<template>
	<div class="p-grid">
		<div class="p-col-12">
			<div class="card">
				<div class="p-grid">
					<div class="p-col-5">
						<Dropdown v-model="marca" :options="marcas" optionLabel="nombre" :placeholder="$t('Seleccione...')" @change="Buscar()"></Dropdown>
						&nbsp;
						<Button :disabled ="$store.state.loading" icon="pi pi-pencil" class="p-button-rounded p-button-secondary" v-if="mostrar && !edicion" @click="edicion=true"/>
						&nbsp;
						<Button :disabled ="$store.state.loading" icon="pi pi-plus" class="p-button-rounded p-button-secondary" v-if="mostrar" @click="productDialog2=true"/>
						&nbsp;
						<Button :disabled ="$store.state.loading" icon="pi pi-trash" class="p-button-rounded p-button-danger" v-if="mostrar && eliminar" @click="eliminar=false"/>
						<Button :disabled ="$store.state.loading" icon="pi pi-trash" class="p-button-rounded p-button-warning" v-if="mostrar && !eliminar" @click="eliminar=true"/>
					</div>
					<div class="p-col-4">
						<div class="p-inputgroup" v-if="mostrar && edicion">
							<span class="p-inputgroup-addon">
								<i class="pi pi-pencil"></i>
							</span>
							<InputText id="nombre" v-model="marca.nombre"/>
							&nbsp;
							<Button :disabled ="$store.state.loading" icon="pi pi-times" class="p-button-rounded p-button-danger"  @click="edicion=false"/>
						</div>				
					</div>

					<div class="p-col-3" align="right">
						<Button :disabled ="$store.state.loading" :label="$t('Nuevo')" icon="pi pi-check" class="p-button-primary" @click="productDialog1=true"/>
						&nbsp;
						<Button :disabled ="$store.state.loading" :label="$t('Guardar')" icon="pi pi-check" class="p-button-secondary" @click="saveProduct()" />
					</div>
				</div>
			</div>
		</div>
		<div class="p-col-12" v-if="marca && !eliminar">
			<div class="card">
				<div class="p-grid">
					<Accordion class="accordion-custom">
						<AccordionTab :header="item.nombre" v-for="(item, index) in products" :key="index" :value="index">
							<Button :disabled ="$store.state.loading" icon="pi pi-pencil" class="p-button-rounded p-button-secondary" @click="item.edit=true" v-if="!item.edit"/>
							&nbsp;
							<Button :disabled ="$store.state.loading" icon="pi pi-plus" class="p-button-rounded p-button-secondary" v-if="mostrar" @click="saveYear(index)"/>
							&nbsp;
							<Button :disabled ="$store.state.loading" icon="pi pi-trash" class="p-button-rounded p-button-danger" v-if="item.del" @click="item.del=false"/>
							<Button :disabled ="$store.state.loading" icon="pi pi-trash" class="p-button-rounded p-button-warning" v-if="!item.del" @click="item.del=true"/>
								<div class="p-inputgroup" v-if="item.edit">
									<span class="p-inputgroup-addon">
										<i class="pi pi-pencil"></i>
									</span>
									<InputText id="nombre" v-model="item.nombre"/>
									&nbsp;
									<Button :disabled ="$store.state.loading" icon="pi pi-times" class="p-button-rounded p-button-danger"  @click="item.edit=false"/>
								</div>				

								<DataTable :value="item.anoveh" responsiveLayout="scroll" v-if="!item.del">
									<Column field="modelon" :header="$t('Modelo')"/>
									<Column field="nombre" :header="$t('Año')" sortable>
										<template #body="slotProps">
											<InputNumber v-model="slotProps.data.nombre" min="1900" integeronly showButtons :disabled="slotProps.data.del==true || slotProps.data.uso>0"/>
										</template>								
									</Column>
									<Column field="valor" :header="$t('Valor')">
										<template #body="slotProps">
											<InputNumber id="horizontal" v-model="slotProps.data.valor" showButtons buttonLayout="horizontal" 
												decrementButtonClass="p-button-danger" incrementButtonClass="p-button-success" incrementButtonIcon="pi pi-plus" 
												decrementButtonIcon="pi pi-minus" min="0" mode="currency" :currency="$store.state.currency" style="max-width:50px;"
												:disabled="slotProps.data.del==true" :minFractionDigits="0"/>
										</template>
									</Column>
									<Column :header="$t('Eliminar')"  headerStyle="width: 100px">
										<template #body="slotProps">
											<Button :disabled ="$store.state.loading" icon="pi pi-trash" class="p-button-rounded p-button-danger" v-if="slotProps.data.del && slotProps.data.uso==0" @click="slotProps.data.del=false"/>
											<Button :disabled ="$store.state.loading" icon="pi pi-trash" class="p-button-rounded p-button-warning" v-if="!slotProps.data.del && slotProps.data.uso==0" @click="slotProps.data.del=true"/>
										</template>
									</Column>
								</DataTable>
						</AccordionTab>
					</Accordion>
				</div>
			</div>
		</div>

				<Dialog v-model:visible="productDialog1" :style="{width: size}" :header="$t('Nuevo')" :modal="true" class="p-fluid" :maximizable="true">
					<br>
                    <div class="p-field">
						<div class="p-grid">
							<div class="p-col-12">
								<label for="name">{{$t('Marca')}}</label>
								<InputText id="nombre" v-model="new_marca"/>
							</div>
						</div>
					</div>
					<template #footer>
						<Button :disabled ="$store.state.loading" :label="$t('Guardar')" icon="pi pi-check" class="p-button-secondary" @click="saveMarca()" />
						<Button :disabled ="$store.state.loading" :label="$t('Cancelar')" icon="pi pi-times" class="p-button-danger" @click="productDialog1=false"/>
					</template>
				</Dialog>
				<Dialog v-model:visible="productDialog2" :style="{width: size}" :header="$t('Nuevo')" :modal="true" class="p-fluid" :maximizable="true">
					<br>
                    <div class="p-field">
						<div class="p-grid">
							<div class="p-col-12">
								<label for="name">{{$t('Modelo')}}</label>
								<InputText id="nombre" v-model="new_modelo"/>
							</div>
						</div>
					</div>
					<template #footer>
						<Button :disabled ="$store.state.loading" :label="$t('Guardar')" icon="pi pi-check" class="p-button-secondary" @click="saveModelo()" />
						<Button :disabled ="$store.state.loading" :label="$t('Cancelar')" icon="pi pi-times" class="p-button-danger" @click="productDialog2=false"/>
					</template>
				</Dialog>
	</div>

</template>

<script>
	import API from "../service/API";
	const Consulta = new API('Configuracion','Vehiculos');

	export default {
		data() {
			return {
				insert: false,
				edit: false,
				del: false,
				marca: null,
				marcas: [],
				products: [],
				productDialog1: false,
				productDialog2: false,
				product: {},
				filters: {},
				submitted: false,
				size: '40vw',
				niveles: null,
                expandedRows: [],
				modelo: null,
				modeloDialog: false,
				yearDialog: false,
				mostrar: false,
				edicion: false,
				eliminar: false,
				new_marca: null,
				new_modelo: null,
			}
		},
		productService: null,
		created() {
			this.$store.commit('Validar',this.$route);
			if (this.$store.state.mobile){this.size = '90vw';}
			this.Mostrar();
		},
		mounted() {
			
		},
		methods: {
			Mostrar() {
				this.products = null;
				Consulta.Ini().then(result => {
					this.insert = result.insert;
					this.edit = result.edit;
					this.del = result.delete;
					this.marcas = result.consult;
				}); 
			},
            Buscar(){
                if(this.marca.code>0){
					this.mostrar = false;
                    this.products = [];
					this.edicion = false;
                    Consulta.Procesar('Buscar',{
                        id: this.marca.code,
                    }).then(response => {
                        this.products = response.result;
						this.mostrar = true;
                    });
                }
            },
			openNewModelo() {
				this.modelo = null;
				this.modeloDialog = true;
			},
			openNewYear(data) {
				this.product = data;
				this.yearDialog = true;
			},
			saveProduct() {
				Consulta.Procesar('UPD',{
					marca: this.marca,
					products: this.products,
					eliminar: this.eliminar
				}).then(response => {
					if (response.result) {
						this.$toast.add({
							severity:'success', 
							summary: 'Éxito', 
							detail: 'Información Procesada', 
							life: 3000
						});
						if(this.eliminar){
							this.eliminar = false;
							this.Mostrar();
						} else {
							this.Buscar();  
						}
					} else {
						this.$toast.add({
							severity:'warn', 
							summary: 'Advertencia', 
							detail: 'No fue procesada la solicitud', 
							life: 3000
						});
					}                    
				});	
			},
			saveMarca() {
				Consulta.Procesar('INS1',{
					new_marca: this.new_marca,
				}).then(response => {
					if (response.result) {
						this.$toast.add({
							severity:'success', 
							summary: 'Éxito', 
							detail: 'Información Procesada', 
							life: 3000
						});
                        this.productDialog1 = false;
						this.new_marca = null;
                        this.Mostrar();  
					} else {
						this.$toast.add({
							severity:'warn', 
							summary: 'Advertencia', 
							detail: 'No fue procesada la solicitud', 
							life: 3000
						});
					}
				});	
			},
			saveModelo() {
				Consulta.Procesar('INS2',{
					marca: this.marca,
					new_modelo: this.new_modelo,
				}).then(response => {
					if (response.result) {
						this.$toast.add({
							severity:'success', 
							summary: 'Éxito', 
							detail: 'Información Procesada', 
							life: 3000
						});
                        this.productDialog2 = false;
						this.new_modelo = null;
                        this.Buscar();  
					} else {
						this.$toast.add({
							severity:'warn', 
							summary: 'Advertencia', 
							detail: 'No fue procesada la solicitud', 
							life: 3000
						});
					}
				});	
			},
			saveYear(index) {
				this.$store.commit('Loading');
				this.products[index].anoveh.push({
					id:  		0, 
					year_id: 	0,
					valor: 		0,
					modelo: 	this.products[index].id,
					nombre:		0,
					modelon:	this.products[index].nombre,
					marcan:		this.marca.nombre,
					del:		false
				});
				this.$store.commit('Loading');
			},
			findIndexById(id) {
				let index = -1;
				for (let i = 0; i < this.products.length; i++) {
					if (this.products[i].id === id) {
						index = i;
						break;
					}
				}

				return index;
			},
			createId() {
				let id = '';
				var chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
				for ( var i = 0; i < 5; i++ ) {
					id += chars.charAt(Math.floor(Math.random() * chars.length));
				}
				return id;
			},
			exportCSV() {
				this.$refs.dt.exportCSV();
			},

            expandAll() {
                this.expandedRows = this.products.filter(p => p.id);
            },
            collapseAll() {
                this.expandedRows = null;
            },


		}
	}
</script>

<style scoped lang="scss">
.table-header {
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.product-image {
	width: 100px;
	box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}

.p-dialog .product-image {
	width: 150px;
	margin: 0 auto 2rem auto;
	display: block;
}

.confirmation-content {
	display: flex;
	align-items: center;
	justify-content: center;
}

.product-badge {
	border-radius: 2px;
	padding: .25em .5rem;
	text-transform: uppercase;
	font-weight: 700;
	font-size: 12px;
	letter-spacing: .3px;

	&.status-instock {
		background: #C8E6C9;
		color: #256029;
	}

	&.status-outofstock {
		background: #FFCDD2;
		color: #C63737;
	}

	&.status-lowstock {
		background: #FEEDAF;
		color: #8A5340;
	}
}

.p-datatable-responsive-demo .p-datatable-tbody > tr > td .p-column-title {
    display: none;
}

@media screen and (max-width: 40em) {
    ::v-deep(.p-datatable) {
        &.p-datatable-responsive-demo {
            .p-datatable-thead > tr > th,
            .p-datatable-tfoot > tr > td {
                display: none !important;
            }

            .p-datatable-tbody > tr > td {
                text-align: left;
                display: block;
                width: 100%;
                float: left;
                clear: left;
                border: 0 none;

                .p-column-title {
                    padding: .4rem;
                    min-width: 30%;
                    display: inline-block;
                    margin: -.4em 1em -.4em -.4rem;
                    font-weight: bold;
                }

                &:last-child {
					border-bottom: 1px solid var(--surface-d);
					text-align: center;
				}
				
				.p-rating {
					display: inline-block;
				}
            }
        }
    }
}
</style>
